import React from "react";
import ContentPageLayout from "../components/Organisms/ContentPageLayout";
import { graphql } from "gatsby";
import { WorkTemplateQuery } from "../../graphql-schema-types";
import {
  Heading1,
  Heading3,
  Heading5,
  Caption,
} from "../components/Atoms/Text";
import { css } from "@emotion/core";
import {
  bpMedium,
  bpLarge,
  colorGoldStroke,
  colorGrayStroke,
  colorLight,
} from "../stylingConstants";
import Blockquote from "../components/Atoms/Blockquote";
import PortraitGrid from "../components/Organisms/PortraitGrid";
import { verticalMargin } from "../margins";
import { extractWorkData } from "../formatModels";
import GatsbyImage from "gatsby-image";
import BackLink from "../components/Atoms/BackLink";
import RightColumnShare from "../components/Organisms/RightColumnShare";
import Seo from "../components/Seo";
import { getPersonRoute } from "../routeHelper";
import MaybeExternalLink from "../components/Atoms/MaybeExternalLink";
import { useGlobalSiteData } from "../hooks";
import { trimYearPrefix } from "../polymorphic-utils";

type Props = {
  data: WorkTemplateQuery;
};

const PressImageLink = Caption.withComponent(MaybeExternalLink);

//Arbitrary? Yes.
const firstYearWithMynewsdesk = 2018;

export default function Work({
  data: {
    contentfulWork,
    winningYear,
    winningCategory,
    nomineeYear,
    nomineeCategory,
  },
}: Props) {
  const award =
    winningYear != null || nomineeYear != null
      ? {
          winner: winningYear != null,
          year: winningYear?.year ?? nomineeYear?.year,
          category: trimYearPrefix(
            winningCategory?.title ?? nomineeCategory?.title
          ),
        }
      : null;
  const work = extractWorkData(contentfulWork!);
  const description =
    award &&
    `${award.winner ? "Vinnare av" : "Nominerad till"} ${award.category} ${
      award.year
    }`;
  const {
    socialUrls: { mynewsdesk },
  } = useGlobalSiteData();
  return (
    <ContentPageLayout
      leftColumn={<BackLink />}
      rightColumn={<RightColumnShare />}
    >
      <Seo
        title={work?.title}
        description={description}
        image={work.image?.src}
      />
      <Heading1>{work?.title}</Heading1>
      {award && (
        <Heading3
          css={css`
            color: ${award.winner ? colorGoldStroke : colorGrayStroke};
            margin-top: 8px;
            margin-bottom: 32px;
            @media ${bpMedium} {
              margin-top: 16px;
              margin-bottom: 44px;
            }
            @media ${bpLarge} {
              margin-top: 24px;
              margin-bottom: 52px;
            }
          `}
        >
          {description}
        </Heading3>
      )}
      {work.publishInfo && (
        <>
          <Heading5 css={{ color: colorLight }}>Publicering</Heading5>
          <p css={verticalMargin(4)}>{work.publishInfo}</p>
        </>
      )}
      {work?.authors && (
        <PortraitGrid
          css={css`
            margin-top: 30px;
            margin-bottom: 15px;
            @media ${bpLarge} {
              margin-top: 40px;
              margin-bottom: 20px;
            }
          `}
          people={work.authors.map((author) => ({
            image: author?.image?.fluid!,
            name: author?.name,
            tint: award?.winner ? "gold" : "gray",
            href: author?.slug && getPersonRoute(author.slug),
          }))}
        />
      )}
      {work.juryQuote && (
        <>
          <Heading5 css={{ color: colorLight }}>Juryns motivering</Heading5>
          <Blockquote>{work.juryQuote}</Blockquote>
        </>
      )}
      {work.image && (
        <GatsbyImage
          fluid={work.image}
          alt={"Bidragsbild"}
          css={css`
            width: 100%;
            margin-top: 32px;
            @media ${bpMedium} {
              margin-top: 44px;
            }
          `}
        />
      )}
      {mynewsdesk &&
        parseInt(award?.year) >=
          firstYearWithMynewsdesk && (
            <PressImageLink
              to={mynewsdesk}
              target="_blank"
              css={css`
                color: ${colorGrayStroke};
                text-decoration: underline;
              `}
            >
              Pressbilder på Mynewsdesk
            </PressImageLink>
          )}
    </ContentPageLayout>
  );
}

export const query = graphql`
  query WorkTemplate($id: String) {
    contentfulWork(id: { eq: $id }) {
      ...WorkSelection
    }
    nominated: allContentfulYear(
      filter: {
        awards: {
          elemMatch: { nominatedWorks: { elemMatch: { id: { eq: $id } } } }
        }
      }
    ) {
      distinct(field: year)
    }
    # There must be a way to get the following four queries down to one, but lord help me
    winningYear: contentfulYear(
      awards: { elemMatch: { winningWork: { id: { eq: $id } } } }
    ) {
      year
    }
    nomineeYear: contentfulYear(
      awards: {
        elemMatch: { nominatedWorks: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      year
    }
    winningCategory: contentfulAward(winningWork: { id: { eq: $id } }) {
      title
    }
    nomineeCategory: contentfulAward(
      nominatedWorks: { elemMatch: { id: { eq: $id } } }
    ) {
      title
    }
  }
`;
